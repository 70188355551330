.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #282c34;
}

.Images-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10vw;
}

.Image {
  width: 25%;
  height: auto;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  color: white;
}

h1 {
  font-size: 16px;
  margin-top: 24px;
}

p {
  text-align: center;
  width: 88%;
}

.p-footer {
  text-align: right;
  width: 100%;
}

.App-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 80%;
  height: auto;
  text-align: center;
  padding: 20px;
  color: white;
  background-color: #282c34;
}

.App-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100vw;
  height: 100%;
  color: white;
  background-color: #282c34;
}

.social-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 15px;
}

.social-links a {
  color: white;
  text-decoration: none;
  font-size: 1.2em;
  transition: color 0.3s ease;
}

.social-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 15px;
}

.social-links a {
  color: white;
  text-decoration: none;
  font-size: 1.2em;
  transition: color 0.3s ease;

  /* Ajout des propriétés pour supprimer la surbrillance */
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  touch-action: manipulation;
}

.social-links a:active {
  outline: none;
  background-color: transparent;
}

.social-links a.instagram {
  position: relative;
}

.social-links a.instagram svg {
  fill: white;
  transition: fill 0.3s ease;
}

.social-links a.instagram:hover svg {
  fill: url(#instagram-gradient);
}

.facebook:hover {
  color: #0866ff;
}

@media (max-width: 1024px) {
  .Image {
    width: 33%;
  }
}

@media (max-width: 799px) {
  .Image {
    width: 42%;
  }
}

@media (max-width: 600px) {
  h1 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px 0px 0px 0px;
  }

  .App-body p {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .Image {
    width: 66%;
  }
  .Images-group {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin: 0;
  }

  .App-footer {
    padding-bottom: 24px;
  }
}
